<script setup lang="ts">
import { numberFormat, STATUS_SYSTEM_COLOR_DICTIONARY } from "#imports";

import { toast } from "vue3-toastify";
const localPath = useLocalePath();

withDefaults(
	defineProps<{
		location: "account" | "sidebar";
	}>(),
	{
		location: "account"
	}
);
const MAX_LEVEL = 70;
const MILLION = 1000000;

const { t } = useT();
const { open } = useFunrizeModals();
const { copy } = useClipboard();
const { isDesktop } = useDevice();
const { data: appInitData } = useAppInitData();
const { data: pageProfileData } = useAsyncFetch({
	path: "/rest/page/profile/",
	method: "get",
	options: {
		cached: true
	}
});
const { type, pointsReceived, pointsNeedForNextLevel, level, levelMax, enabled } = useStatusSystem();
const { ready: readyPopper, start: handleStartPopper } = useTimeout(2000, { controls: true, immediate: false });

const idLogin = computed(() => `ID#${appInitData.value?.login}`);
const isProfileVerified = computed(() =>
	["approved", "verified"].includes(pageProfileData.value?.payload?.documentConfirmStatus)
);
const bgColor = computed(() => `var(${STATUS_SYSTEM_COLOR_DICTIONARY[type.value]})`);
const progress = computed(() => {
	if (pointsReceived.value && pointsNeedForNextLevel.value) {
		return `${(100 / pointsNeedForNextLevel.value) * pointsReceived.value}%`;
	}

	return "0%";
});
const nextLevel = computed(() => {
	if (level.value && levelMax.value) {
		if (levelMax.value === MAX_LEVEL) {
			return MAX_LEVEL;
		}

		return level.value + 1;
	}
});
const isMillionsPoints = computed(() => pointsNeedForNextLevel.value >= MILLION);
const isSeasonActive = computed(() => appInitData.value?.season?.isActive);

const handleCopyLoginId = () => {
	copy(idLogin.value);
	toast.success(t("Account ID copied."), {
		icon: h("i", { class: "toast-icon icon-checked" }),
		toastId: "account-id-copy",
		theme: toast.THEME.DARK,
		position: isDesktop ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_CENTER,
		transition: toast.TRANSITIONS.SLIDE,
		autoClose: 5000
	});
};

const handleOpenRewardsStatusSystem = () => {
	open("LazyOModalRewardsStatusSystem");
	dispatchGAEvent({
		event: "open_popup",
		form_name: "funrize_club_rewards",
		location: "profile",
		quantity: level.value
	});
};

const handleClickPopper = () => {
	if (isMillionsPoints.value) {
		handleStartPopper();
	}
};

const formatNumberWithMillions = (number: number) => {
	if (number >= MILLION) {
		return `${(number / MILLION).toFixed(1).replace(".", ",")}M`;
	}

	return numberFormat(number);
};
</script>
<template>
	<template v-if="location === 'account'">
		<div class="status-level locale-account" :style="{ minHeight: enabled ? '243px' : 'auto' }">
			<NuxtImg
				:src="`/nuxt-img/status-system/slides/${type}.png`"
				:alt="type"
				class="shield"
				data-tid="account-avatar"
				format="webp"
				width="244"
				height="195"
			/>
			<div class="info">
				<div class="header">
					<div class="status-title">
						<AText as="div" variant="tucson" :modifiers="['bold', 'capitalize']" class="text-cannes">
							<span class="status-tag">
								{{ t(type) }}
								<NuxtIcon
									v-if="enabled"
									name="20/question"
									data-tid="account-rewards-info"
									filled
									@click="handleOpenRewardsStatusSystem"
								/>
							</span>
						</AText>
						<APopper
							placement="top"
							:content="`${numberFormat(pointsReceived ?? 0)} / ${numberFormat(pointsNeedForNextLevel ?? 0)}`"
							:show="!readyPopper"
						>
							<AText as="div" variant="toledo" :class="{ 'status-xp': isMillionsPoints }" @click="handleClickPopper">
								{{ formatNumberWithMillions(pointsReceived ?? 0) }} /
								<AText variant="toledo" :modifiers="['bold']" class="text-cannes">
									{{ formatNumberWithMillions(pointsNeedForNextLevel ?? 0) }} XP
								</AText>
							</AText>
						</APopper>
					</div>

					<div class="account-info">
						<ABadge v-if="isProfileVerified" variant="skew" background="var(--cartagena)" :autosize="true">
							<AText :modifiers="['uppercase', 'bold']" data-tid="account-verified">{{ t("verified") }}</AText>
						</ABadge>
						<ABadge v-else variant="skew" background="var(--cabo)" :autosize="true">
							<AText data-tid="account-not-verified" :modifiers="['uppercase', 'bold']">{{ t("not verified") }}</AText>
						</ABadge>
						<AText variant="toledo" class="text-cannes" data-tid="copy-login" @click="handleCopyLoginId">
							{{ idLogin }} <NuxtIcon name="20/copy" filled class="copy-icon" />
						</AText>
					</div>
				</div>

				<MStatusSystemProgress v-if="enabled" :level="level || 1" :next-level="nextLevel || 1" :progress="progress" />
			</div>
		</div>
	</template>
	<template v-if="location === 'sidebar'">
		<div class="status-level locale-sidebar text-cannes">
			<div class="top">
				<NuxtLink :to="localPath('/game/')">
					<NuxtImg
						:src="`/nuxt-img/status-system/slides/${type}.png`"
						alt="status"
						format="webp"
						width="56"
						height="44"
					/>
				</NuxtLink>
				<div class="info">
					<AText variant="toledo" as="div" :modifiers="['bold', 'capitalize']">{{ t(type) }}</AText>
					<AText variant="topeka" class="level_txt" as="div">
						<i18n-t keypath="Level {key}">
							<template #key>
								<b class="text-cannes">{{ level || 1 }}</b>
							</template>
						</i18n-t>
					</AText>
				</div>
			</div>

			<MStatusSystemProgress
				v-if="enabled"
				location="sidebar"
				:level="level || 1"
				:next-level="nextLevel || 1"
				:progress="progress"
			/>

			<div v-if="isSeasonActive" class="freeze-status">
				<NuxtImg src="/nuxt-img/status-system/lock.png" width="32" height="32" format="webp" alt="lock" />
				<AText class="text-cannes" variant="topeka" :modifiers="['semibold', 'center']">
					{{
						t(
							"Don't worry! Your progress in Funrize Club is frozen but will be restored once you finish Hawaii Adventure Season."
						)
					}}
				</AText>
			</div>
		</div>
	</template>
</template>
<style scoped lang="scss">
.status-level {
	display: flex;
	background: v-bind(bgColor);
	border-radius: 8px;
}

.locale-account {
	&.status-level {
		align-items: center;
		gap: gutter(1.5);
		padding: gutter(3);
		width: 100%;
		max-width: 676px;
		position: relative;

		@media (max-width: 1190px) {
			align-items: flex-start;
		}

		@include media-breakpoint-down(lg) {
			padding: gutter(3) gutter(2) gutter(2.5);
		}
	}

	.progress {
		margin-top: gutter(4.5);

		@media (min-width: 1024px) and (max-width: 1190px) {
			margin-top: gutter(3);
		}

		@include media-breakpoint-down(lg) {
			position: absolute;
			left: 16px;
			bottom: 20px;
			width: calc(100% - 32px);
		}
	}

	.shield {
		@media (max-width: 1290px) {
			width: 140px;
			height: 112px;
		}
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: gutter(2.5);

		@media (max-width: 1190px) {
			flex-direction: column;
			align-items: flex-start;
		}

		@include media-breakpoint-down(lg) {
			flex-direction: row;
			align-items: flex-end;
			justify-content: flex-start;
			gap: gutter(5);
		}

		@include media-breakpoint-down(sm) {
			flex-direction: column;
			align-items: flex-start;
			gap: gutter(2.5);
		}
	}

	.info {
		width: 100%;
	}

	p.toledo {
		color: var(--ciputat);

		@include media-breakpoint-down(lg) {
			font-size: 14px;

			span {
				font-size: 14px;
			}
		}
	}

	.status-title {
		display: flex;
		flex-direction: column;
		gap: gutter(0.5);
	}

	.status-tag {
		display: inline-block;
		line-height: 46px;
		position: relative;

		&:deep(.nuxt-icon) {
			cursor: pointer;
			position: absolute;
			right: -28px;
			top: -8px;

			svg {
				font-size: 20px;
			}
		}

		@include media-breakpoint-down(lg) {
			font-size: 24px;
			line-height: 32px;
		}
	}

	.status-xp {
		cursor: pointer;
	}

	.account-info {
		display: flex;
		flex-direction: column;
		gap: gutter(2);
		padding-top: gutter(1.25);

		.toledo {
			cursor: pointer;
		}

		@include media-breakpoint-down(lg) {
			padding-top: 0;
			gap: gutter(1);

			.toledo {
				font-size: 14px;
			}
		}
	}

	.badge.skew {
		transform: skewX(-20deg);

		&::before {
			content: none;
		}
	}

	.tempe {
		@include media-breakpoint-down(sm) {
			font-size: 12px;
		}
	}

	.copy-icon {
		font-size: 20px;

		&:deep(path) {
			fill: var(--cannes);
		}
	}
}
.locale-sidebar {
	position: relative;
	overflow: hidden;
	flex-shrink: 0;

	&:has(.freeze-status) {
		min-height: 120px;
	}

	&.status-level {
		flex-direction: column;
		gap: gutter(0.5);
		padding: gutter(1.5) gutter(1);
	}

	.top {
		display: flex;
		align-items: center;
		gap: 4px;

		img {
			flex-shrink: 0;
		}

		.info {
			display: inline-flex;
			flex-direction: column;
			gap: gutter(0.25);
		}
	}

	.middle {
		display: flex;
		align-items: center;

		.box-img {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			margin: 0 0 0 gutter(-0.5) !important;
			border: 1px solid #4c13b4 !important;
			background: #10054b;
			flex-shrink: 0;
			width: 28px;
			height: 28px;
		}
	}
	.level_txt {
		color: var(--ciputat);
	}

	.freeze-status {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--cardenas);
		backdrop-filter: blur(4px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: gutter(1);
		border-radius: 8px;
		padding: gutter(1);
	}
}
</style>
<style lang="scss">
.Toastify__toast-container {
	.icon-checked {
		background-image: url(/assets/icons/20/checked.svg);
	}
}
</style>
